<script>
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable prettier/prettier */
</script>
<template>
  <div class="pa-4">
    <template>
      <div class="d-flex justify-end my-1 mx-2">
        <v-btn depressed @click="createnewSale()" color="primary" dark
          ><v-icon>mdi-plus</v-icon> Sale</v-btn
        >
      </div>
    </template>
    <div class="d-flex justify-center">
      <v-card width="100%">
        <v-toolbar height="50" dark color="primary">
          {{ nomAccesory }} Sales
          <v-divider class="mx-2" vertical />
          <v-autocomplete
            v-if="filterPatients == 'patients'"
            class="mx-1"
            :label="'Filter by ' + getpatientN + 's'"
            :items="patients"
            v-model="bodyreq.patientUuid"
            item-text="fullname"
            item-value="uuid"
            style="max-width: 300px"
            prepend-inner-icon="mdi-account-search"
            clearable
            hide-details
            outlined
            @change="filterSales"
            @click:clear="resetFilter('patients')"
            dense
          ></v-autocomplete>
          <v-autocomplete
            v-if="filterEmployees == 'employees'"
            class="mx-1"
            label="Filter by Employees"
            :items="workers"
            v-model="bodyreq.employeeUuid"
            item-text="fullname"
            item-value="uuid"
            style="max-width: 300px"
            prepend-inner-icon="mdi-account-search"
            clearable
            @change="filterSales"
            @click:clear="resetFilter('employees')"
            hide-details
            outlined
            dense
          ></v-autocomplete>
          <v-layout class="ma-4" v-if="filterRanges == 'custom-select'">
            <v-row class="mt-3" no-gutters>
              <v-col class="mt-3">
                <ma-date-picker v-model="dateFrom" label="From" past />
              </v-col>
              <v-col class="ml-2 mt-3">
                <ma-date-picker v-model="dateTo" label="To" />
              </v-col>
            </v-row>
          </v-layout>
          <v-menu offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                color="primary"
                class="ml-auto"
                :loading="loading"
                depressed
                v-bind="attrs"
                v-on="on"
              >
                <v-icon left>mdi-tune</v-icon>
                Filter
              </v-btn>
            </template>

            <v-list width="140" nav dense>
              <v-list-item link @click="filterEmployees = 'employees'">
                <v-list-item-title>Employees</v-list-item-title>
              </v-list-item>
              <v-divider />

              <v-list-item link @click="filterPatients = 'patients'">
                <v-list-item-title>{{ getpatientN + "s" }}</v-list-item-title>
              </v-list-item>
              <v-divider />
              <v-list-item link @click="filterRanges = 'custom-select'">
                <v-list-item-title>Custom Select</v-list-item-title>
              </v-list-item>
              <v-divider />
              <v-list-item
                v-for="interval in intervals"
                :key="interval.value"
                link
                @click="setIntervar(interval.value)"
              >
                <v-list-item-title>{{ interval.label }}</v-list-item-title>
              </v-list-item>
              <v-list-item link @click="resetFilter">
                <v-list-item-title class="red--text"
                  >Clear Filters</v-list-item-title
                >
              </v-list-item>
            </v-list>
          </v-menu>
        </v-toolbar>
        {{/* Interval indicator */}}
        <v-layout class="pa-4" column>
          <div class="mr-2 text-capitalize">
            {{ filterRanges.replace("-", " ") }}
          </div>
          <v-layout column class="text-body-1 text--secondary">
            <div>
              From:
              <span class="text-caption">{{
                prettyDate(bodyreq.dates[0])
              }}</span>
            </div>
            <div>
              To:
              <span class="text-caption">{{
                prettyDate(bodyreq.dates[1])
              }}</span>
            </div>
          </v-layout>
        </v-layout>

        <v-divider />
        <v-card-text>
          <template>
            <v-data-table
              :headers="headers"
              :items="salesProducts"
              :options.sync="options"
              :loading="loading"
              single-expand
              show-expand
              :expanded.sync="expanded"
              :server-items-length="totalitems"
              :footer-props="footerProps"
              class="elevation-1"
            >
              <template v-slot:[`item.patient`]="{ item }">
                {{ item.patient ? item.patient.fullname : "-" }}
              </template>
              <template v-slot:[`item.createdBy`]="{ item }">
                {{ item.createdBy.fullname }}
              </template>
              <template v-slot:[`item.status`]="{ item }">
                <span :class="`${getColor(item.status)}--text`">
                  {{ item.status }}</span
                >
              </template>
              <template v-slot:[`item.createdAt`]="{ item }">
                {{ paymentDate(item.createdAt) }}
              </template>
              <template v-slot:[`item.totalAmount`]="{ item }">
                {{ item.totalAmount | currency }}
              </template>
              <template v-slot:[`item.discount`]="{ item }">
                {{ item.discount | currency }}
              </template>
              <template v-slot:[`item.actions`]="{ item }">
                <template
                  v-if="(isAdmin || isSuper) && item.status == 'UNCONFIRMED'"
                >
                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        @click="goToAction(item, 'cancel')"
                        v-on="on"
                        v-bind="attrs"
                        icon
                        color="error"
                        ><v-icon>mdi-cancel</v-icon></v-btn
                      >
                    </template>
                    <span>Cancel Sale</span>
                  </v-tooltip>
                </template>
                <template
                  v-if="(isAdmin || isSuper) && item.status == 'CONFIRMED'"
                >
                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        @click="goToAction(item, 'refund')"
                        v-on="on"
                        v-bind="attrs"
                        icon
                        color="warning"
                        ><v-icon>mdi-autorenew</v-icon></v-btn
                      >
                    </template>
                    <span>Refund Sale</span>
                  </v-tooltip>
                </template>
                <template v-if="item.status == 'CONFIRMED'">
                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        @click="goToInvoice(item)"
                        v-on="on"
                        v-bind="attrs"
                        icon
                        color="success"
                        ><v-icon>mdi-note-text</v-icon></v-btn
                      >
                    </template>
                    <span>View Sale</span>
                  </v-tooltip>
                </template>
              </template>

              <template v-slot:expanded-item="{ headers, item }">
                <td :colspan="headers.length">
                  <div class="d-flex ma-4">
                    <div class="col-sm-4">
                      <div>
                        <span><strong>Sale:</strong> #{{ item.id }}</span>
                      </div>
                      <template v-if="item.patient">
                        <div class="my-4">
                          <span
                            ><strong>{{ getpatientN }}:</strong>
                            {{ item.patient.fullname }}</span
                          >
                        </div>
                      </template>
                      <template v-else>
                        <div class="my-4">
                          <v-tooltip top>
                            <template v-slot:activator="{ on, attrs }">
                              <span v-on="on" v-bind="attrs"
                                ><strong>Note:</strong>
                                {{ prettyNotes(item.note) }}</span
                              >
                            </template>
                            <p class="tooltipnote">
                              {{ prettyNotesTool(item.note) }}
                            </p>
                          </v-tooltip>
                        </div>
                      </template>

                      <div class="my-4">
                        <span
                          ><strong>Employee:</strong>
                          {{ item.createdBy.fullname }}</span
                        >
                      </div>
                      <div class="my-4">
                        <span
                          ><strong>Final Amount:</strong>
                          {{ item.totalAmount | currency }}</span
                        >
                      </div>
                      <div class="my-4">
                        <span
                          ><strong>Discount:</strong>
                          {{ item.discount | currency }}</span
                        >
                      </div>
                      <div class="my-4">
                        <span
                          ><strong>Created At:</strong>
                          {{ paymentDate(item.createdAt) }}</span
                        >
                      </div>
                    </div>
                    <div class="col-sm-6">
                      <template>
                        <v-card>
                          <v-card-title primary-title>
                            {{ nomAccesory }}
                          </v-card-title>

                          <v-simple-table>
                            <template v-slot:default>
                              <thead>
                                <tr>
                                  <th class="text-left">{{ showProd }}</th>
                                  <th class="text-left" align-end>Amount</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr
                                  v-for="(p, index) in item.products"
                                  :key="index"
                                >
                                  <td>{{ p.description }}</td>
                                  <td>
                                    {{ p.balance | currency }}
                                  </td>
                                </tr>
                              </tbody>
                            </template>
                          </v-simple-table>
                        </v-card>
                      </template>
                    </div>
                    <template v-if="item.status == 'UNCONFIRMED'">
                      <div class="col-sm-2 d-flex align-center">
                        <v-btn @click="paySale(item)" color="success"
                          >Paid</v-btn
                        >
                      </div>
                    </template>
                  </div>
                </td>
              </template>
            </v-data-table>
            <v-dialog
              v-model="invoice.dialogInvoice"
              persistent
              :overlay="false"
              max-width="600px"
              transition="dialog-transition"
            >
              <v-card>
                <v-card-title primary-title>
                  <v-spacer></v-spacer>
                  <v-btn
                    color="success"
                    class="mx-2"
                    icon
                    @click="generateReport"
                    ><v-icon>mdi-file-pdf</v-icon> Export
                  </v-btn>
                </v-card-title>
                <v-card-text class="mt-4">
                  <template v-if="invoice.item != null">
                    <div id="pdf" class="mx-4 mt-4">
                      <h2 class="bg-custom2">Payment confirmation</h2>
                      <template v-if="invoice.item.patient">
                        <div class="patient">
                          <h5>{{ getpatientN }}</h5>
                        </div>
                        <p class="mx-0">
                          {{ invoice.item.patient.fullname }}
                        </p>
                        <p class="textemail font-italic">
                          {{ invoice.item.patient.email }}
                        </p>
                      </template>

                      <v-divider></v-divider>
                      <div class="row fact-info my-1 mx-2">
                        <div class="col-3">
                          <h5>Facture :</h5>
                          <p>{{ "No." + getNoFacture(invoice.item) }}</p>
                        </div>
                        <div class="col-3">
                          <h5>Payment Type</h5>
                          <p>{{ getpaymentType(invoice.item.payment) }}</p>
                        </div>
                        <div class="col-3">
                          <h5>Final Amount</h5>
                          <p>{{ invoice.item.payment.amount | currency }}</p>
                        </div>
                        <div class="col-3">
                          <h5>Discount</h5>
                          <p>{{ invoice.item.discount | currency }}</p>
                        </div>
                      </div>
                      <v-divider class="mt-1"></v-divider>
                      <div class="products">
                        <h3>{{ nomAccesory }}</h3>
                      </div>

                      <div class="row fact-info mt-3">
                        <v-simple-table class="margins">
                          <template v-slot:default>
                            <thead>
                              <tr>
                                <th class="text-center">{{ showProd }}</th>
                                <th class="text-center" align-end>Amount</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr
                                v-for="(p, index) in invoice.item.products"
                                :key="index"
                              >
                                <td class="text-center">
                                  {{ p.description }}
                                </td>
                                <td class="text-center">
                                  {{ p.balance | currency }}
                                </td>
                              </tr>
                            </tbody>
                          </template>
                        </v-simple-table>
                      </div>
                      <v-divider></v-divider>
                    </div>
                  </template>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn text @click="cancelInvoice" color="error"
                    >Cancel</v-btn
                  >
                </v-card-actions>
              </v-card>
            </v-dialog>
            <v-dialog
              v-model="actionsSales.dialogActions"
              persistent
              :overlay="false"
              max-width="300px"
              transition="dialog-transition"
            >
              <v-card>
                <v-card-title
                  :class="
                    actionsSales.typeAction == 'refund'
                      ? 'text-h5 white--text  orange lighten-2'
                      : 'text-h5 white--text  red lighten-2'
                  "
                >
                  {{
                    actionsSales.typeAction == "refund"
                      ? "Refund Sale"
                      : "Cancel Sale"
                  }}
                </v-card-title>
                <v-card-text class="d-flex justify-center my-3 text-h6">
                  Are you sure?
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn @click="cancelAction" text>Cancel</v-btn>
                  <v-btn
                    @click="confirmActions(actionsSales.typeAction)"
                    text
                    :color="
                      actionsSales.typeAction == 'refund'
                        ? 'orange lighten-2'
                        : 'red lighten-2'
                    "
                    :disabled="loading"
                    :loading="loading"
                    >Confirm</v-btn
                  >
                </v-card-actions>
              </v-card>
            </v-dialog>
          </template>
        </v-card-text>
      </v-card>
    </div>
    <template v-if="newSale">
      <div class="d-flex justify-center mt-4">
        <v-sheet
          color="white"
          class="pa-2"
          elevation="2"
          height="100%"
          width="100%"
          max-width="1000"
        >
          <template>
            <div class="d-flex justify-end">
              <v-btn @click="clearAll()" small color="error"
                ><v-icon>mdi-cancel</v-icon> Cancel</v-btn
              >
            </div>
            <v-layout row wrap class="my-4 px-4">
              <template v-if="saleReq == null">
                <v-flex class="pr-1" xs6>
                  <v-card-title primary-title>
                    Add {{ nomAccesory }}
                  </v-card-title>

                  <div class="d-flex justify-center">
                    <div class="col-sm-12 d-flex">
                      <v-autocomplete
                        :items="accessories"
                        item-text="name"
                        outlined
                        :loading="loading"
                        :disabled="loading"
                        dense
                        item-value="id"
                        v-model="accessorySelect"
                        :label="nomAccesory"
                      ></v-autocomplete>
                    </div>
                  </div>
                  <div class="d-flex justify-center">
                    <template v-if="accessorySelect != null">
                      <div class="col-sm-8">
                        <v-card elevation="1">
                          <template>
                            <v-simple-table style="background: transparent">
                              <thead>
                                <tr>
                                  <th>{{ showProd }}</th>
                                  <th>Min-Price</th>
                                  <th>Max-Price</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <td>{{ nameAcces }}</td>
                                  <td>{{ minPrice | currency }}</td>
                                  <td>{{ maxPrice | currency }}</td>
                                </tr>
                              </tbody>
                            </v-simple-table>
                            <template v-if="!validPrice">
                              <span class="red--text">
                                {{
                                  "The cost must be between " +
                                  minPrice +
                                  " and " +
                                  maxPrice
                                }}
                              </span>
                            </template>
                            <template v-if="price != 0 && validPrice">
                              <v-layout row wrap class="ma-2" justify-center>
                                <template v-if="!validDiscost">
                                  <span class="red--text">
                                    {{
                                      "The maximum discount is " + maxDiscount
                                    }}
                                  </span>
                                </template>
                              </v-layout>
                            </template>
                          </template>
                        </v-card>
                      </div>
                      <div class="col-sm-3">
                        <v-text-field
                          class="my-1"
                          name="price"
                          outlined
                          dense
                          @click="clickAmount"
                          label="Price"
                          v-model="price"
                        ></v-text-field>
                        <v-text-field
                          class="my-1"
                          name="discount"
                          outlined
                          :disabled="!validPrice || price == 0"
                          dense
                          @click="clickdisco"
                          label="Discount"
                          v-model="discount"
                        ></v-text-field>
                      </div>
                      <div class="col-sm-1 d-flex align-center">
                        <v-btn
                          @click="addItem(accessorySel)"
                          icon
                          color="success"
                          :disabled="!validPrice || !validDiscost"
                        >
                          <v-icon>mdi-plus</v-icon></v-btn
                        >
                      </div>
                    </template>
                  </div>
                </v-flex>
                <v-divider class="mx-4" vertical />
              </template>

              <template>
                <v-flex xs5>
                  <template v-if="items.length > 0">
                    <div class="col-sm-12">
                      <v-card>
                        <template v-if="saleReq != null">
                          <v-card-title primary-title>
                            Sale: {{ "#" + saleReq.id }}
                          </v-card-title>
                          <v-card-subtitle>
                            {{ getpatientN }}:
                            {{
                              saleReq.patient ? saleReq.patient.fullname : "-"
                            }}
                          </v-card-subtitle>
                        </template>
                        <template v-else>
                          <v-card-title primary-title>
                            Sale Request
                          </v-card-title>
                        </template>

                        <v-simple-table>
                          <thead>
                            <tr>
                              <th>Product</th>
                              <th>Price</th>

                              <th></th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr v-for="(item, index) in items" :key="index">
                              <td>{{ item.name }}</td>
                              <td>{{ item.price | currency }}</td>

                              <td>
                                <template v-if="saleReq == null">
                                  <v-tooltip top>
                                    <template v-slot:activator="{ on, attrs }">
                                      <v-btn
                                        @click="deleteProduct(index)"
                                        icon
                                        v-bind="attrs"
                                        v-on="on"
                                        text
                                        color="error"
                                      >
                                        <v-icon>mdi-delete</v-icon>
                                      </v-btn>
                                    </template>
                                    <span>Delete {{ showProd }}</span>
                                  </v-tooltip>
                                </template>
                              </td>
                            </tr>
                            <tr>
                              <th>Total Amount</th>
                              <td>{{ totalprice | currency }}</td>
                            </tr>
                            <tr>
                              <th>Total Discount</th>
                              <td>{{ totaldiscount | currency }}</td>
                            </tr>
                            <template v-if="saleReq != null">
                              <tr>
                                <th>Final Amount</th>
                                <td>{{ saleReq.totalAmount | currency }}</td>
                              </tr>
                            </template>
                          </tbody>
                        </v-simple-table>

                        <template v-if="saleReq == null">
                          <template>
                            <v-switch
                              class="mx-3"
                              v-model="nopatient"
                              inset
                              :label="'Not ' + getpatientN"
                            ></v-switch>
                          </template>
                          <template v-if="!nopatient">
                            <v-autocomplete
                              outlined
                              dense
                              class="mx-3"
                              :items="patients"
                              item-text="fullname"
                              item-value="uuid"
                              :loading="loading"
                              v-model="patientSelect"
                              :label="getpatientN"
                            ></v-autocomplete>
                          </template>
                          <template v-else>
                            <v-textarea
                              outlined
                              v-model="note"
                              class="ma-3"
                              label="Description"
                            ></v-textarea>
                          </template>

                          <v-card-actions class="d-flex justify-end">
                            <v-btn @click="cancelCreate()" text color="error"
                              >Cancel</v-btn
                            >
                            <v-btn
                              :disabled="disabledBtn || loading"
                              text
                              :loading="loading"
                              @click="createSale"
                              color="success"
                              >Create Sale</v-btn
                            >
                          </v-card-actions>
                        </template>
                      </v-card>
                    </div>
                  </template>
                </v-flex>

                <template v-if="saleReq != null">
                  <v-divider class="mx-4" vertical />
                  <v-flex xs6>
                    <make-payment
                      :external="true"
                      :nopatient="_notPatient"
                      :sale="saleReq"
                      @cancel="clearAll()"
                      @payment="clearAll()"
                    />
                  </v-flex>
                </template>
              </template>
            </v-layout>
          </template>
        </v-sheet>
      </div>
    </template>
    <div ref="scrollable"></div>
  </div>
</template>
<script>
import moment from "moment";
import IconVisa from "@/components/icons/iconVisa.vue";
import IconMastercard from "@/components/icons/iconMastercard.vue";
import MaDatePicker from "@/components/MaDatePicker/MaDatePicker.vue";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import { mapActions, mapState, mapGetters, mapMutations } from "vuex";
import MakePayment from "../Patients/MakePayment.vue";
import { getAPI } from "@/api/axios-base";
export default {
  components: { MakePayment, MaDatePicker, IconVisa, IconMastercard },
  data() {
    return {
      actionsSales: {
        dialogActions: false,
        typeAction: "refund",
        saleId: null,
      },
      invoice: {
        dialogInvoice: false,
        item: null,
      },
      footerProps: { "items-per-page-options": [10, 15, 50, 100] },
      filterEmployees: "",
      filterPatients: "",
      filterRanges: "this-year",
      bodyreq: {
        patientUuid: null,
        employeeUuid: null,
        dates: ["", ""],
        range: {
          limit: 100,
          offset: 0,
        },
      },
      expanded: [],
      patients: [],
      loading: false,
      accessorySelect: null,
      newSale: false,
      nopatient: false,
      patientSelect: null,
      note: null,
      authSaleCode: null,
      options: {},
      dateFrom: "",
      dateTo: "",
      discount: 0,
      price: 0,
      saleReq: null,

      items: [],
      intervals: [
        { label: "Today", value: "today" },
        { label: "Yesterday", value: "yesterday" },
        { label: "This week", value: "this-week" },
        { label: "Last week", value: "last-week" },
        { label: "This month", value: "this-month" },
        { label: "Last month", value: "last-month" },
        { label: "This year", value: "this-year" },
        { label: "Last year", value: "last-year" },
      ],
    };
  },
  computed: {
    ...mapState("crmConfigurationsModule", ["salesProducts"]),
    ...mapGetters(["nomAccesory", "isAdmin", "isSuper", "getpatientN"]),
    ...mapState("crmSettingsModule", ["accessories"]),
    ...mapState("crmEmployeeModule", ["workers"]),

    ...mapState(["totalitems"]),
    _notPatient() {
      if (
        this.saleReq != null &&
        this.saleReq.note != null &&
        this.saleReq.note != undefined
      ) {
        return true;
      }
      return false;
    },
    headers() {
      return [
        {
          text: "Id",
          align: "start",
          sortable: false,
          value: "id",
        },
        { text: this.getpatientN, value: "patient", sortable: false },
        { text: "Employee", value: "createdBy", sortable: false },
        { text: "Amount", value: "totalAmount", align: "end", sortable: false },
        { text: "Discount", value: "discount", align: "end", sortable: false },
        {
          text: "Paid",
          value: "status",
          align: "center",
          sortable: false,
        },
        { text: "Created At", value: "createdAt", sortable: false },
        { text: "Actions", value: "actions", sortable: false },
        { text: "", value: "data-table-expand", sortable: false },
      ];
    },

    disabledBtn() {
      if (this.nopatient && this.note != null) {
        return false;
      }
      if (!this.nopatient && this.patientSelect != null) {
        return false;
      }
      return true;
    },

    accessorySel() {
      const acces = this.accessories;
      return acces.filter((acc) => acc.id == this.accessorySelect)[0] || null;
    },
    totaldiscount() {
      let disc = 0;
      if (this.items.length == 0) {
        return disc;
      }
      if (this.saleReq != null) {
        return Number(this.saleReq.discount);
      }
      this.items.forEach((element) => {
        disc = disc + Number(element.discount);
      });
      return disc;
    },
    totalprice() {
      let pric = 0;
      if (this.items.length == 0) {
        return pric;
      }
      this.items.forEach((element) => {
        pric = pric + Number(element.price);
      });
      return pric;
    },

    nameAcces() {
      if (this.accessorySel == null) {
        return "";
      }
      return this.accessorySel.name;
    },

    validPrice() {
      if (this.price == null || this.price == undefined) {
        return false;
      }
      if (
        Number(this.price) < Number(this.minPrice) ||
        Number(this.price) > Number(this.maxPrice)
      ) {
        return false;
      }
      return true;
    },
    validDiscost() {
      if (Number(this.discount) > Number(this.maxDiscount)) {
        return false;
      }
      return true;
    },
    maxDiscount() {
      return Number(this.price) - Number(this.minPrice);
    },

    /* price() {
      if (this.accessorySel == null) {
        return 0;
      }

      return parseFloat(this.accessorySel.price);
    }, */
    minPrice() {
      if (this.accessorySel == null) {
        return 0;
      }

      return parseFloat(this.accessorySel.minValue);
    },
    maxPrice() {
      if (this.accessorySel == null) {
        return 0;
      }

      return parseFloat(this.accessorySel.maxValue);
    },
    showProd() {
      if (this.nomAccesory == "Accessories") {
        return "Accessory";
      }
      return this.nomAccesory.substring(0, this.nomAccesory.length - 1);
    },

    /*INTERVALS*/
    intervalToday() {
      const from = moment();
      from.hour(0);
      from.minute(0);
      from.second(0);
      const to = moment();

      return {
        date1: from,
        date2: to,
      };
    },
    intervarYesterDay() {
      const from = moment().subtract(1, "days").hour(0).minute(0).second(0);
      const to = moment().subtract(1, "days").hour(23).minute(59).second(59);

      return {
        date1: from,
        date2: to,
      };
    },
    intervarThisWeek() {
      const from = moment().day(0).hour(0).minute(0).second(0);
      const to = moment();

      return {
        date1: from,
        date2: to,
      };
    },
    intervarLastWeek() {
      const from = moment().subtract(1, "week").hour(0).minute(0).second(0);
      const to = moment()
        .subtract(1, "week")
        .add(6, "days")
        .hour(23)
        .minute(59)
        .second(59);

      return {
        date1: from,
        date2: to,
      };
    },
    intervarThisMonth() {
      const from = moment().date(1).hour(0).minute(0).second(0);
      const to = moment().hour(23).minute(59).second(59);

      return {
        date1: from,
        date2: to,
      };
    },
    intervarLastMonth() {
      const month = moment().month() + 1;

      // Si coincide con enero retornar el mes actual
      if (month == 1) {
        return this.intervarThisMonth;
      }

      const from = moment()
        .subtract(1, "month")
        .date(1)
        .hour(0)
        .minute(0)
        .second(0);
      const to = moment()
        .date(1)
        .subtract(1, "days")
        .hour(23)
        .minute(59)
        .second(59);

      return {
        date1: from,
        date2: to,
      };
    },
    intervarThisYear() {
      const from = moment().month(0).date(1).hour(0).minute(0).second(0);
      const to = moment();

      return {
        date1: from,
        date2: to,
      };
    },
    intervarLastYear() {
      const from = moment()
        .subtract(1, "year")
        .month(0)
        .date(1)
        .hour(0)
        .minute(0)
        .second(0);
      const to = moment()
        .month(0)
        .date(1)
        .subtract(1, "day")
        .hour(23)
        .minute(59)
        .second(59);

      return {
        date1: from,
        date2: to,
      };
    },
  },
  watch: {
    dateFrom(val) {
      if (
        val !== null &&
        val !== this.dateTo &&
        this.dateTo !== null &&
        this.dateTo !== ""
      ) {
        this.filterSales();
      }
    },
    dateTo(val) {
      if (
        val !== null &&
        val !== this.dateFrom &&
        this.dateFrom !== null &&
        this.dateFrom !== ""
      ) {
        this.filterSales();
      }
    },
    options(val) {
      if (val != {}) {
        this.filterSales();
      }
    },
  },
  methods: {
    ...mapActions("crmSettingsModule", ["actListAllNomenclator"]),
    ...mapActions("crmEmployeeModule", ["actGetEmployees"]),
    ...mapActions("crmConfigurationsModule", [
      "actCreateSale",
      "actGetSales",
      "actCancelSale",
      "actRefundSale",
    ]),
    ...mapMutations("crmSettingsModule", ["mutAccessories"]),
    ...mapMutations(["mutReqRange"]),

    prettyNotes(note) {
      if (note.length > 20) {
        return note.substring(0, 20) + "...";
      }
      return note;
    },
    prettyNotesTool(note) {
      /*   if (note.length > 200) {
        const medio = Math.trunc(note.length / 3);
        const notemit1 = note.substring(0, medio);
        const notemit2 = note.substring(medio, medio * 2);
        const notemit3 = note.substring(medio * 2, note.length);
        return notemit1 + "\n" + notemit2 + "\n" + notemit3;
      }
      if (note.length > 30) {
        const medio = Math.trunc(note.length / 2);
        const notemit1 = note.substring(0, medio);
        const notemit2 = note.substring(medio, note.length);
        return notemit1 + "\n" + notemit2;
      } */
      return note;
    },

    goToInvoice(item) {
      this.invoice = { dialogInvoice: true, item: item };
    },
    getNoFacture(item) {
      return item.id;
    },
    getpaymentType(item) {
      if (item.type == "External_Card_Reader") {
        return "Card Reader";
      }
      return item.type;
    },
    generateReport() {
      window.html2canvas = html2canvas;
      let doc = new jsPDF("p", "pt", "a4");
      doc.html(document.querySelector("#pdf"), {
        callback: function (pdf) {
          pdf.save("Payment-confirm.pdf");
        },
      });
      this.cancelInvoice();
    },

    cancelInvoice() {
      this.invoice = { dialogInvoice: false, item: null };
    },

    goToAction(item, type) {
      this.actionsSales = {
        dialogActions: true,
        typeAction: type,
        saleId: item.id,
      };
    },
    cancelAction() {
      this.actionsSales = {
        dialogActions: false,
        typeAction: "refund",
        saleId: null,
      };
    },
    async confirmActions(type) {
      this.loading = true;
      if (type == "refund") {
        await this.actRefundSale(this.actionsSales.saleId);
      } else {
        await this.actCancelSale(this.actionsSales.saleId);
      }

      await this.filterSales();
      this.cancelAction();
      this.loading = false;
    },

    prettyDate(date) {
      if (date === null || date === "") return "";

      return moment(date).format("dddd D MMMM YYYY, h:mm:ss a");
    },
    paymentDate(date) {
      return new Date(date).toISOString().slice(0, 10);
    },

    addItem(item) {
      const additem = {
        name: item.name,
        price: this.price,
        id: item.id,
        discount: this.discount,
      };
      this.items.push(additem);
      this.discount = 0;
      this.price = 0;
      this.accessorySelect = null;
      this.scrolltoBotton();
    },
    async listAccessories() {
      this.loading = true;
      const accessory = (await getAPI(`/sprocedures/listNom/Accessory`)).data;
      this.mutAccessories(accessory);
      this.loading = false;
    },

    async getPatients() {
      this.loading = true;
      this.patients = (await getAPI("/patient/listAllPatients")).data;
      this.loading = false;
    },

    async createSale() {
      this.loading = true;
      const acc = this.items.map((item) => {
        return {
          type: "Accessory",
          id: item.id,
          price: Number(item.price),
        };
      });

      let req = {};
      req = this.nopatient
        ? {
            note: this.note,
            accesories: acc,
            discount: this.totaldiscount,
          }
        : {
            patientUuid: this.patientSelect,
            accesories: acc,
            discount: this.totaldiscount,
          };

      this.saleReq = await this.actCreateSale(req);
      this.loading = false;
      this.nopatient = false;
      this.note = null;
      await this.filterSales();
      this.loading = false;
    },

    paySale(item) {
      if (this.newSale) {
        this.saleReq = null;
        this.items = [];
      }

      this.newSale = true;
      this.saleReq = item;
      this.saleReq.products.forEach((element) => {
        this.items.push({ name: element.description, price: element.balance });
      });

      this.scrolltoBotton();
    },

    clearAll() {
      this.accessorySelect = null;
      this.newSale = false;
      this.patientSelect = null;
      this.discount = 0;
      this.price = 0;
      this.nopatient = false;
      this.note = null;
      this.saleReq = null;
      this.items = [];
      this.filterSales();
    },
    cancelCreate() {
      this.items = [];
    },
    deleteProduct(index) {
      this.items.splice(index, 1);
    },

    createnewSale() {
      this.newSale = true;
      this.scrolltoBotton();
    },

    scrolltoBotton() {
      this.$refs["scrollable"].scrollIntoView({
        behavior: "smooth",
      });
    },

    /**Filters */
    setIntervar(interval) {
      this.filterRanges = interval;
      this.filterSales();
    },

    async filterSales() {
      this.loading = true;
      const { page, itemsPerPage } = this.options;
      const itmper = itemsPerPage != -1 ? itemsPerPage : 50;
      let body = this.bodyreq;
      body.range = {
        limit: itmper,
        offset: (page - 1) * itmper,
      };
      this.mutReqRange(body.range);
      switch (this.filterRanges) {
        case "today":
          body.dates = [this.intervalToday.date1, this.intervalToday.date2];
          break;
        case "yesterday":
          body.dates = [
            this.intervarYesterDay.date1,
            this.intervarYesterDay.date2,
          ];
          break;
        case "this-week":
          body.dates = [
            this.intervarThisWeek.date1,
            this.intervarThisWeek.date2,
          ];
          break;
        case "last-week":
          body.dates = [
            this.intervarLastWeek.date1,
            this.intervarLastWeek.date2,
          ];
          break;
        case "this-month":
          body.dates = [
            this.intervarThisMonth.date1,
            this.intervarThisMonth.date2,
          ];
          break;
        case "last-month":
          body.dates = [
            this.intervarLastMonth.date1,
            this.intervarLastMonth.date2,
          ];
          break;
        case "this-year":
          body.dates = [
            this.intervarThisYear.date1,
            this.intervarThisYear.date2,
          ];
          break;
        case "last-year":
          body.dates = [
            this.intervarLastYear.date1,
            this.intervarLastYear.date2,
          ];
          break;
        case "custom-select":
          body.dates = [
            moment(this.dateFrom).startOf("day"),
            moment(this.dateTo).endOf("day"),
          ];
          break;
      }
      body = this.cleanNull(body);
      await this.actGetSales(body);
      this.loading = false;
    },

    /*Reset Filter*/
    resetFilter(value) {
      switch (value) {
        case "custom-select":
          this.filterRanges = "this-year";
          break;
        case "patients":
          this.filterPatients = "";
          break;
        case "employees":
          this.filterEmployees = "";
          break;

        default:
          this.filterPatients = "";
          this.filterEmployees = "";
          this.filterRanges = "this-year";
          this.bodyreq = {
            patientUuid: null,
            employeeUuid: null,
            dates: ["", ""],
          };
          this.filterSales();
          break;
      }
    },

    cleanNull(obj) {
      for (var propName in obj) {
        if (
          obj[propName] === null ||
          obj[propName] === undefined ||
          obj[propName] === ""
        ) {
          delete obj[propName];
        }
      }
      return obj;
    },
    getColor(value) {
      switch (value) {
        case "CONFIRMED":
          return "green";
        case "REFOUNDED":
          return "orange";
        case "UNCONFIRMED":
          return "red";
        default:
          return "gray";
      }
    },

    clickAmount() {
      if (this.price == 0) {
        this.price = "";
      }
    },
    clickdisco() {
      if (this.discount == 0) {
        this.discount = "";
      }
    },
  },
  async mounted() {
    this.setIntervar(this.filterRanges);

    await this.listAccessories();
    this.getPatients();
    this.actGetEmployees();
  },
};
</script>
<style lang="scss" scope>
.tooltipnote {
  max-width: 650px !important;
  padding: 10px !important;
}
.margins {
  width: 100%;
}
.textemail {
  margin-top: -15px;
}
.products {
  text-align: center;
}
#pdf {
  margin: 15px;
  height: 300px;
  width: 500px;
  position: relative;
}

.fact-info {
  margin-top: 5px;
  text-align: justify;
  justify-content: center;
}
.textemail {
  margin-top: 5px !important;
  margin-bottom: 15px;
}
.bg-custom2 {
  margin-top: 2rem;
  font: bold 100% sans-serif;
  letter-spacing: 0.5em;
  text-align: center;
  text-transform: uppercase;

  border-radius: 0.25em;

  margin: 0 0 1em;
  padding: 0.5em 0;
}
</style>
